import { combineReducers } from 'redux';
import authReducer from './auth/reducer';
import paymentReducer from './payment/reducer';
import userReducer from './payment/reducer';
import registerReducer from './register/reducer';

const rootReducer = combineReducers({
    auth: authReducer,
    payment: paymentReducer,
    user: userReducer,
    register: registerReducer,
});

export default rootReducer;
