export const SET_INSTALLMENTS = '@payments/SET_INSTALLMENTS';
export const SET_PRICE = '@payments/SET_PRICE';
export const SET_CART_ITEMS = '@payments/SET_CART_ITEMS';

export const SET_PAYMENT_OPTION = '@payments/SET_PAYMENT_OPTION';

export const OPEN_CONTRACT = '@payments/OPEN_CONTRACT';
export const CLOSE_CONTRACT = '@payments/CLOSE_CONTRACT';


