import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import rootReducer from './modules';

export const initStore = (initialState = {}) => {
    return createStore(
        rootReducer,
        // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
        initialState,
        process.env.NODE_ENV === 'development'
            ? compose(applyMiddleware(thunk), applyMiddleware(logger))
            :
            compose(applyMiddleware(thunk))
    );
};
