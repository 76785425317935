import styled from 'styled-components';
import { CircularProgress } from '@material-ui/core'

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
`;

export const CustomCircularProgress = styled(CircularProgress)`
    margin-bottom: 24px;
    & > svg {
        color: rgb(252, 121, 84);
    }
`;