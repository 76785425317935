import React, { Suspense, lazy } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import PrivateRoute from './components/PrivateRoute';

import { Container, CustomCircularProgress } from './styles';

const CheckoutLazy = lazy(() => import('./pages/checkout'));
const HomeLazy = lazy(() => import('./pages/home'));

export default function Routes() {
    return (
        <BrowserRouter>
            <Suspense fallback={<Container><CustomCircularProgress /></Container>}>
                <Switch>
                    <Route exact path="/" component={HomeLazy} />
                    <Route path="/acessar" component={HomeLazy} />
                    <PrivateRoute path="/checkout" component={CheckoutLazy} />
                </Switch>
            </Suspense>
        </BrowserRouter>
    )
}
