import React from 'react';
import Routes from './routes';
import { ThemeProvider as MaterialUiProvider } from '@material-ui/core/styles';
import { ThemeProvider as StyledComponentProvider } from 'styled-components';
import theme from './theme';

import { GlobalStyle } from './components/StyleGuide';

function App() {
  return (
    <StyledComponentProvider theme={theme} injectFirst>
      <MaterialUiProvider theme={theme}>
        <GlobalStyle />
          <div className="App">
            <Routes />
          </div>
      </MaterialUiProvider>
    </StyledComponentProvider>
  );
}

export default App;
