import {
    OPEN_FORGOT_PASSWORD,
    CLOSE_FORGOT_PASSWORD,
    OPEN_TERMS,
    CLOSE_TERMS
} from './constants';

const initialState = {
    forgotPassword: {
        open: false,
    },
    terms: {
        open: false,
    }
};

export default function paymentReducer(state = initialState, action) {
    switch (action.type) {
        case OPEN_FORGOT_PASSWORD:
            return {
                ...state,
                forgotPassword: {
                    ...state.forgotPassword,
                    open: true,
                },
            };
        case CLOSE_FORGOT_PASSWORD:
            return {
                ...state,
                forgotPassword: {
                    ...state.forgotPassword,
                    open: false,
                },
            };
        case OPEN_TERMS:
            return {
                ...state,
                terms: {
                    ...state.terms,
                    open: true,
                },
            };
        case CLOSE_TERMS:
            return {
                ...state,
                terms: {
                    ...state.terms,
                    open: false,
                },
            };
        default:
            return state;
    }
}
