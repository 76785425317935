import { createMuiTheme } from '@material-ui/core/styles';

// Create a theme instance.
const theme = createMuiTheme({
    overrides: {
        MuiButton: {
            root: {
                padding: '0 13px',
                minWidth: 100,
                height: 32,
            },
        },
        MuiTab: {
            root: {
                minWidth: 'auto !important',
                fontSize: 15,
                marginLeft: 45,
                padding: 6,
                '&$selected': {
                    fontWeight: 'bold',
                },
            },
        },
        MuiOutlinedInput: {
            input: {
                padding: 16,
                height: 'min-content',
            },
            multiline: {
                padding: 8,
            },
        },
        MuiAppBar: {
            root: {
                boxShadow: 'inset 0px -1px 0px 0px rgba(0, 0, 0, 0.1)',
                height: 72,
            },
        },
        MuiToolbar: {
            root: {
                height: 72,
            },
        },
    },
    props: {
        MuiButton: {
            disableElevation: true,
        },
        MuiTab: {
            disableRipple: true,
        },
        MuiAppBar: {
            elevation: 0,
        },
        MuiCheckbox: {
            color: 'primary',
        },
        MuiRadio: {
            color: 'primary',
            disableRipple: true
        }
    },
    palette: {
        background: {
            default: '#fff',
        },
        primary: {
            main: 'rgb(77, 146, 237)',
        },
    },
    typography: {
        fontFamily: [
            'Proxima Nova',
            'Roboto',
            'BlinkMacSystemFont',
            'Segoe UI',
            'Helvetica Neue',
            'Arial',
            'sans-serif',
        ].join(','),
        button: {
            textTransform: 'none',
            fontSize: 13,
        },
    },
    colors: {
        black75: 'rgba(0, 0, 0, 0.75)',
        black50: 'rgba(0, 0, 0, 0.50)',
        black40: 'rgba(0, 0, 0, 0.40)',
        black35: 'rgba(0, 0, 0, 0.35)',
        notebook: 'rgb(34, 40, 46)',
        blue: 'rgb(66, 153, 252)',
        ciano: 'rgb(77, 146, 237)',
        green: 'rgb(49, 201, 142)',
        yellow: 'rgb(254, 195, 60)',
        red: 'rgb(254, 63, 75)',
    },
});

export default theme;
