import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Route, Redirect } from 'react-router-dom';

import { CircularProgress } from '@material-ui/core'

const Container = styled.div`
    display: flex !important;
    flex-direction: column;
    justify-content: ${props => props.loading ? 'center' : 'flex-start'};
    align-items: ${props => props.loading ? 'center' : 'flex-start'};
    height: 100vh;
    width: 100vw;
    font-family: 'Proxima Nova';
`;

const CustomCircularProgress = styled(CircularProgress)`
    margin-bottom: 24px;
    & > svg {
        color: rgb(252, 121, 84);
    }
`;

function PrivateRoute({ component: Component, ...rest }) {
    const [loading, setLoading] = useState(true);
    const [isAuth, setIsAuth] = useState(false);

    useEffect(() => {
        async function isAuthenticated() {
            const token = localStorage.getItem('token');

            if (token) {
                setTimeout(() => {
                    setIsAuth(true);
                    setLoading(false);
                }, 1000);
            } else {
                window.location = `${window.location.origin}/acessar`;
            }
        }

        isAuthenticated();
    }, []);

    if (loading) {
        return <Container loading={1}>
            <CustomCircularProgress />
            <h3>Autenticando</h3>
        </Container>
    }

    return <Route {...rest} render={props => (
        isAuth
            ? (<Component {...props} />)
            : (
                <Redirect
                    to={{
                        pathname: '/',
                        state: { from: props.location }
                    }}
                />
            )
    )} />
}

export default PrivateRoute;