import {
    SET_INSTALLMENTS,
    SET_PRICE,
    SET_PAYMENT_OPTION,
    SET_CART_ITEMS,
    OPEN_CONTRACT,
    CLOSE_CONTRACT,
} from './constants';

const initialState = {
    cart: {
        price: {
            a_vista: 0,
            parcelado: 0,
        },
        items: [],
    },
    contract: {
        open: false,
    },
    creditCard: {
        open: false,
        installments: 0,
        price: {
            parcelado: 0,
            a_vista: 0,
        }
    },
    choosePayment: {
        open: false,
        paymentOption: '',
    },
    boletoModal: {
        open: false,
    }
};

export default function paymentReducer(state = initialState, action) {
    switch (action.type) {
        case SET_INSTALLMENTS:
            return {
                ...state,
                creditCard: {
                    ...state.creditCard,
                    installments: action.payload.installments,
                }
            }
        case SET_PRICE:
            return {
                ...state,
                creditCard: {
                    ...state.creditCard,
                    price: action.payload.price,
                }
            }
        case SET_PAYMENT_OPTION:
            return {
                ...state,
                choosePayment: {
                    ...state.choosePayment,
                    paymentOption: action.payload.type,
                }
            }
        case SET_CART_ITEMS:
            return {
                ...state,
                cart: {
                    ...state.cart,
                    items: action.payload.items,
                }
            }
        case OPEN_CONTRACT:
            return {
                ...state,
                contract: {
                    ...state.contract,
                    open: true,
                }
            }
        case CLOSE_CONTRACT:
            return {
                ...state,
                contract: {
                    ...state.contract,
                    open: false,
                }
            }
        default:
            return state;
    }
}

