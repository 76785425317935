export const OPEN_REGISTER = '@register/OPEN_REGISTER';
export const CLOSE_REGISTER = '@register/CLOSE_REGISTER';

export const SET_STEPS = '@register/SET_STEPS';
export const NEXT_STEP = '@register/NEXT_STEP';
export const PREVIOUS_STEP = '@register/PREVIOUS_STEP';

export const SUBMIT_ADDRESS = '@register/SUBMIT_ADDRESS';
export const SUBMIT_PERSONAL_DATA = '@register/SUBMIT_PERSONAL_DATA';
export const SUBMIT_CREDIT_CARD_OWNER = '@register/SUBMIT_CREDIT_CARD_OWNER';

export const SET_CREDIT_CARD_OWNER = '@register/SET_CREDIT_CARD_OWNER';
export const INCREASE_OWNER_STEP = '@register/INCREASE_OWNER_STEP';
export const DECREASE_OWNER_STEP = '@register/DECREASE_OWNER_STEP';

export const INCREASE_PAYMENT_FEEDBACK = '@register/INCREASE_PAYMENT_FEEDBACK';
export const DECREASE_PAYMENT_FEEDBACK = '@register/DECREASE_PAYMENT_FEEDBACK';


