import {
    NEXT_STEP,
    PREVIOUS_STEP,
    SET_STEPS,
    OPEN_REGISTER,
    CLOSE_REGISTER,
    SUBMIT_ADDRESS,
    SUBMIT_PERSONAL_DATA,
    SET_CREDIT_CARD_OWNER,
    SUBMIT_CREDIT_CARD_OWNER,
    INCREASE_OWNER_STEP,
    DECREASE_OWNER_STEP,
    INCREASE_PAYMENT_FEEDBACK,
    DECREASE_PAYMENT_FEEDBACK,
} from './constants';

const initialState = {
    open: false,
    steps: [
        'Dados pessoais',
        'Endereço',
        'Forma de pagamento',
        'Pagamento',
    ],
    step: 0,
    owner: false,
    personalData: {
        name: '',
        email: '',
        cpf: '',
        rg: '',
        ddd: '',
        phohe: '',
    },
    creditCardOwner: {
        name: '',
        email: '',
        cpf: '',
        rg: '',
        ddd: '',
        phohe: '',
        zipcode: '',
        address: '',
        number: '',
        complement: '',
        neighborhood: '',
        city: '',
        state: '',
    },
    address: {
        zipcode: '',
        address: '',
        number: '',
        complement: '',
        neighborhood: '',
        city: '',
        state: '',
    }
};

export default function registerReducer(state = initialState, action) {
    switch (action.type) {
        case OPEN_REGISTER:
            return {
                ...state,
                open: true,
            }
        case CLOSE_REGISTER:
            return {
                ...state,
                open: false,
            }
        case SET_STEPS:
            return {
                ...state,
                steps: action.payload.steps,
            }
        case NEXT_STEP:
            if (state.step < state.steps.length) {
                return {
                    ...state,
                    step: state.step + 1,
                }
            }

            break;
        case PREVIOUS_STEP:
            if (state.step !== 0) {
                return {
                    ...state,
                    step: state.step - 1,
                }
            }

            break;
        case SUBMIT_ADDRESS:
            return {
                ...state,
                address: { ...action.payload },
            }
        case SUBMIT_PERSONAL_DATA:
            return {
                ...state,
                personalData: { ...action.payload },
            }
        case SUBMIT_CREDIT_CARD_OWNER:
            return {
                ...state,
                creditCardOwner: { ...action.payload },
            }
        case SET_CREDIT_CARD_OWNER:
            return {
                ...state,
                owner: action.payload.status,
            }
        case INCREASE_OWNER_STEP:
            if (!state.owner) {
                const copy = state.steps;
                if (!copy.includes('Responsável financeiro')) {
                    copy.splice(3, 0, 'Responsável financeiro');

                    return {
                        ...state,
                        steps: copy
                    }
                }
            }

            break;
        case DECREASE_OWNER_STEP:
            if (!state.owner) {
                const copy = state.steps;
                const filter = (val) => val === 'Responsável financeiro' ;
                const index = copy.findIndex(filter);

                copy.splice(index, 1);

                return {
                    ...state,
                    steps: copy,
                }
            }

            break;
        case INCREASE_PAYMENT_FEEDBACK:
            return {
                ...state,
                steps: [...state.steps, 'Confirmação']
            }
        case DECREASE_PAYMENT_FEEDBACK:
            const copy = state.steps;
            copy.pop();

            return {
                ...state,
                steps: copy
            }
        default:
            return state;
    }
}
