import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
    @font-face {
        font-family: 'Proxima Nova';
        src: url('/static/fonts/ProximaNova-Regular.woff2') format('woff2');
        font-weight: normal;
    }

    @font-face {
        font-family: 'Proxima Nova';
        src: url('/static/fonts/ProximaNova-Bold.woff2') format('woff2');
        font-weight: bold;
    }

    @font-face {
        font-family: 'Proxima Nova';
        src: url('/static/fonts/ProximaNova-Medium.woff2') format('woff2');
        font-weight: 500;
    }

    @font-face {
        font-family: 'Proxima Nova';
        src: url('/static/fonts/ProximaNova-Semibold.woff2') format('woff2');
        font-weight: 600;
    }

    @font-face {
        font-family: 'Proxima Nova';
        src: url('/static/fonts/ProximaNova-Extrabld.woff2') format('woff2');
        font-weight: 800;
    }

    * {
        padding: 0;
        margin: 0;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
    }

    body {
        padding: 0;
        margin: 0;
    }
`;
